import { Router } from "@reach/router";
import { Layout } from 'antd';
import Posts from './components/posts'
import { Row, Col } from 'antd';
import './App.css';

const { Footer, Content } = Layout;


function App() {
  return (
    <Layout>
      <Content style={{ margin: '24px 16px 0' }}>
        <Row>
          <Col span={4}> </Col>
          <Col span={8}>
            <Posts path="/" />
          </Col>
          <Col span={4}> </Col>
        </Row>
        
      </Content>
      <Footer> </Footer>
    </Layout>
    
  );
}

export default App;