import React, { useEffect, useState } from "react";
import { Comment, List, Col, Row } from 'antd';

import { Form, Input, Button } from 'antd';
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                "https://post_worker.kenneth0875.workers.dev/posts"
            );
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);

    const onFinish = (values) => {
        console.log(values);
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: values.post.username, content: values.post.content, title: values.post.title })
        };
        fetch("https://post_worker.kenneth0875.workers.dev/posts", requestOptions)
            .then(response => response.json())
            .then(data => setPosts(data));
    };


    return (
        <Col>
            <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                <Form.Item
                    name={['post', 'title']}
                    label="Title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['post', 'username']}
                    label="UserName"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name={['post', 'content']} label="Content" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                        Post
                    </Button>
                </Form.Item>
            </Form>
            <Row>
                <List
                    className="comment-list"
                    header={`${posts.length} posts`}
                    itemLayout="horizontal"
                    dataSource={posts}
                    renderItem={item => (
                        <li>
                            <Comment
                                author={item.title}
                                content={item.content}
                                datetime={item.username}
                            />
                        </li>
                    )}
                />
            </Row>
        </Col>
        

        
    );
};

export default Posts;